
// Recaptcha
window.esignContact = window.esignContact || {};

esignContact.init = function () {
  esignContact.formAjax();
};

/* Captcha */
esignContact.formAjax = function () {
  $('.form-ajax-recaptcha').submit(function(e) {
    e.preventDefault();
    var $form = $(this);


    if($form.hasClass('validate')){
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != ''){
      grecaptcha.reset();
    }

    var checkCount = 0;
    var checkRecaptcha = setInterval(function() {
      if (typeof grecaptcha !== 'undefined') {
        console.log('recaptcha loaded for submit!', grecaptcha);
        grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));
        clearInterval(checkRecaptcha);
      } else if (checkCount > 66) { // wait max. 10 seconds
        clearInterval(checkRecaptcha);
      }
      checkCount += 1;
    }, 150);

    return false;
  });
};

var submitRecaptchaForm = function (token){
  esignContact.recaptchaFormSend($('#contact-form'));
};
var submitRecaptchaFormCTA = function (token){
  esignContact.recaptchaFormSend($('#cta-form'));
};

/**
 * async ReCaptcha inladen en toevegen aan elk recaptcha blokje
 */
var onloadReCaptchaCallback = function() {
  $('.g-recaptcha').each(function(i,el){
    var attributes = {
      'sitekey'  : $(el).data('sitekey'),
      'size'     : $(el).data('size'),
      'callback' : $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid',widgetid);
  });
};

esignContact.recaptchaFormSend = function(form){

  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  form_data = $form.serializeArray();

  $.post($form.attr('action'), form_data, function (data) {

    $('li').removeClass('error');
    var $result = $form.find('.result');

    if (data.errors === false) {
      $form.html(data.result);

      if(typeof ga === "function"){
        ga('send', 'pageview', $form.attr('action'));
      }

    } else {
      $result.html(data.result);
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr('disabled');

  }).fail(function(response) {
    alert('Error: ' + response.responseText);
  });

};

require([
  'requirejs/require',
  'js/libs/validation/languages/jquery.validationEngine-nl',
  'js/libs/validation/jquery.validationEngine',
], function() {
  $(function() {

    if(!Modernizr.touch) {
      $('.validate').validationEngine();
    } else {
      $('.validate').validationEngine({scroll: false});
    }

  });


  // initialize on docready
  $(esignContact.init);
});

define("../resources/assets/js/contact", function(){});

